<template>
    <header class="kta-header">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <router-link class="navbar-brand" :to="'/'" aria-current="page">KTA</router-link>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0 w-100">
                        <li class="nav-item">
                            <router-link class="nav-link" :to="'/'" aria-current="page">{{ getTranslate.MENU_MAIN }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="'/devices'" aria-current="page">{{ getTranslate.MENU_DEVICES }}</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" :to="'/users'" aria-current="page">{{ getTranslate.MENU_USERS }}</router-link>
                        </li>
                        <hr class="d-block d-lg-none">
                        <li class="nav-item dropdown ms-lg-auto">
                            <KTALanguage />
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#" @click.prevent="logout()" aria-current="page">{{ getTranslate.MENU_LOGOUT }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import KTALanguage from '@/components/KTALanguage.vue'

export default {

    name: "KTAHeader",

    components: {

        KTALanguage
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'logout'
        ])
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/header.scss';
</style>