<template>
    <div class="kta-modal-mask">
        <div class="kta-modal-wrapper">
            <div 
                class="kta-modal-container" 
                :class="{ 
                    'kta-modal-container--mdfixed' : size && size == 'mdf',
                    'kta-modal-container--lgfixed' : size && size == 'lgf',
                    'kta-modal-container--xlfixed' : size && size == 'xlf'
                }
            ">
                <div class="kta-modal-header">
                    <div class="kta-modal-header-close material-icons" @click="$emit('closeModal', true)">
                        clear
                    </div>
                    <slot name="header">
                        <div class="kta-modal-header-text">
                            {{ header }}
                        </div>
                        <div class="kta-modal-header-subtext d-none d-md-block" v-if="fieldsRequired">
                            <span class="kta-modal-header-warning">⚹</span> {{ getTranslate.FORM_REQUIRED }}
                        </div>
                    </slot>
                </div>
                <div class="kta-modal-body">
                    <slot name="body"></slot>
                </div>
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

    name: 'KTAModal',

    data() {

        return {

            modal: null
        }
    },

    props: {

        header: {

            type: String,
            required: true
        },

        fieldsRequired: {
            type: Boolean
        },

        size: {

            type: String,
            required: false
        },

        show: {

            type: Boolean,
            required: false,
            default: true
        }
    },

    emits: [

        'closeModal'
    ],

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    mounted() {

        const self = this
        const modalWrapper = document.getElementsByClassName('kta-modal-wrapper')[0]
        const modalBody = document.getElementsByClassName('kta-modal-container')[0]
        const scrollbarWidth = window.innerWidth - document.body.offsetWidth

        document.body.style.overflow = this.show ? 'hidden' : ''
        document.body.style.paddingRight = this.show ? scrollbarWidth + 'px' : ''

        // Переносим модальное окно в корень body [головняк с позиционированием = disable]
        this.modal = document.getElementsByClassName('kta-modal-mask')[0]
        document.body.appendChild(this.modal)

        // Клик вне модального окна закрывает его
        modalWrapper.addEventListener('mousedown', function(event) {

            if(!modalBody.contains(event.target)) {

                self.$emit('closeModal', true)
            }
        })

        // Закрытие модального окна по нажатию Esc
        document.addEventListener('keyup', event => event.code == "Escape" ? this.$emit('closeModal', true) : null )
    },

    beforeUnmount() {

        document.body.style.overflow = ''
        document.body.style.paddingRight = ''

        document.body.removeChild(this.modal)
    },

    watch: {

        show(mode) {

            const scrollbarWidth = window.innerWidth - document.body.offsetWidth

            document.body.style.overflow = mode ? 'hidden' : ''
            document.body.style.paddingRight = mode ? scrollbarWidth + 'px' : ''

        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/modal.scss';
</style>