<template>

    <div class="kta-device">
        <div class="kta-device__map">
            <KTADeviceMap :deviceId="id" />
        </div>
        <div class="kta-device__info">
            <KTADeviceInfo :deviceId="id" />
        </div>
    </div>

</template>

<script>
import KTADeviceMap from '@/components/device/KTADeviceMap.vue'
import KTADeviceInfo from '@/components/device/KTADeviceInfo.vue'

export default {

    name: 'KTADevice',

    props: {

        id: {

            type: String,
            required: true
        }
    },

    components: {

        KTADeviceMap, 
        KTADeviceInfo
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/device';
</style>