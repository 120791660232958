<template>

    <form class="kta-device-track" @submit.prevent="submitTrack()">

        <div 
            v-if="'success' in trackMsg && !trackMsg.success"
            class="kta-device-info__message alert alert-danger alert-dismissible mb-4"
        >
            {{ trackMsg.text }}
            <button type="button" class="btn-close" @click="setTrackMsg({})"></button>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="kta-device-track__start">
                    <label class="kta-device-track__start-label">{{ getTranslate.TRACK_START }}:</label>
                    <VDatePicker 
                        mode="dateTime" 
                        v-model="dateStart" 
                        :popover="false" 
                        is24hr 
                        locale="ru" 
                    >
                        <template #default="{ togglePopover, inputValue, inputEvents }">
                            <div class="input-group">
                                <button
                                    type="button"
                                    class="input-group-text"
                                    @click="() => togglePopover()"
                                >
                                    <span class="material-icons">
                                        calendar_month
                                    </span>
                                </button>
                                <input
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    class="form-control"
                                />
                            </div>
                        </template>
                    </VDatePicker>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="kta-device-track__finish">
                    <label class="kta-device-track__finish-label">{{ getTranslate.TRACK_FINISH }}:</label>
                    <VDatePicker 
                        mode="dateTime" 
                        v-model="dateFinish" 
                        :popover="false" 
                        is24hr
                        locale="ru" 
                    >
                        <template #default="{ togglePopover, inputValue, inputEvents }">
                            <div class="input-group">
                                <button
                                    type="button"
                                    class="input-group-text"
                                    @click="() => togglePopover()"
                                >
                                    <span class="material-icons">
                                        calendar_month
                                    </span>
                                </button>
                                <input
                                    :value="inputValue"
                                    v-on="inputEvents"
                                    class="form-control"
                                />
                            </div>
                        </template>
                    </VDatePicker>
                </div>
            </div>
        </div>

        <div class="mt-4 kta-device-track__btns">

            <button 
                type="button" 
                class="btn btn-outline-primary me-3 kta-device-track__btn" 
                @click.prevent="$emit('closeModal', true)"
            >
                {{ getTranslate.BTN_CANCEL }}
            </button>

            <button 
                type="submit" 
                class="btn btn-primary kta-device-track__btn"
            >
                {{ getTranslate.BTN_REQUEST }}
            </button>

        </div>

    </form>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {

    name: 'KTADeviceTrack',

    data() {

        return {

            dateStart:  0,
            dateFinish: 0
        }
    },

    props: {

        deviceId: {

            type: String,
            required: true
        }
    },

    computed: {

        ...mapState({

            track: state => state.track.track,
            trackProcess: state => state.track.trackProcess,
            trackMsg: state => state.track.trackMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readTrack'
        ]),

        ...mapMutations([

            'setTrackMsg'
        ]),

        submitTrack() {

            const start = new Date(this.dateStart).getTime()
            const finish = new Date(this.dateFinish).getTime()

            let data = {

                start,
                finish,
                id: this.deviceId
            }

            this.readTrack(data)
        }
    },

    mounted() {

        this.dateStart  = (new Date().getTime()) - 86400 * 1000 // Текущее время - 24 часа в милисекундах
        this.dateFinish = new Date().getTime() 
    },

    beforeUnmount() {

        this.setTrackMsg({})
    },

    watch: {

        trackMsg(msg) {

            if(msg.success) {

                this.$emit('closeModal')
            }
        }
    },

    emits: [

        'closeModal'
    ]
}
</script>

<style lang="scss">
@import 'v-calendar/style.css';
</style>