<template>

    <div class="kta-auth">

        <div class="kta-auth__descr">
            KAN 
            Telematic 
            Application
        </div>

        <div 
            class="kta-auth__error"
            v-if="'success' in authMsg && !authMsg.success"
        >
            {{ getTranslate[authMsg.text] || authMsg.text }}
        </div>

        <form class="kta-auth__form" @submit.prevent="submitForm()">

            <input 
                type="text" 
                class="form-control kta-auth__form-input" 
                placeholder="Логин"
                v-model="userLogin"
            >

            <input 
                type="password" 
                class="form-control kta-auth__form-input" 
                autocomplete="on" 
                placeholder="Пароль"
                v-model="userPass"
            >

            <button 
                type="submit" 
                class="btn btn-primary w-100 kta-auth__form-submit"
            >
                Войти
            </button>

        </form>

    </div>

</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {

    name: 'KTAAuth',

    data() {

        return {

            userLogin: null,
            userPass: null
        }
    },

    computed: {

        ...mapState({

            authProcess:    state => state.auth.authProcess,
            authMsg:        state => state.auth.authMsg,
            token:          state => state.token.token
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'sendAuth'
        ]),

        submitForm() {

            this.sendAuth({ login: this.userLogin, pass: this.userPass })
        }
    },

    watch: {

        token(token) {

            if(token) {

                this.$router.push('/')
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/auth';
</style>