import { createStore } from 'vuex'

import config       from '@/store/modules/config.js'
import token        from '@/store/modules/token.js'
import requests     from '@/store/modules/requests.js'
import language     from '@/store/modules/language.js'
import translater   from '@/store/modules/translater.js'
import auth         from '@/store/modules/auth.js'
import users        from '@/store/modules/users.js'
import user         from '@/store/modules/user.js'
import points       from '@/store/modules/points.js'
import devices      from '@/store/modules/devices.js'
import device       from '@/store/modules/device.js'
import track        from '@/store/modules/track.js'

export default createStore({

    modules: {

        config,
        token, 
        requests, 
        language, 
        translater, 
        auth, 
        users,
        user,
        points,
        devices,
        device,
        track
    }
})
