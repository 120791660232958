export default {

    state: {

        users:                  [],
        usersProcess:           false,
        usersMsg:               {},

        createUserProcess:      false,
        createUserMsg:          {},

        updateUserProcess:      false,
        updateUserMsg:          {},

        deleteUserProcess:      false,
        deleteUserMsg:          {}
    },

    actions: {

        /**
         * createUser
         * Создаёт нового пользователя
         * @param {Object} payload - поля формы создания пользователя [KTAUsers.vue]
         */
        async createUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.createUserProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/users.php`, 
                    method: 'POST',
                    data: payload,
                    token
                })

                if(!request.error) {

                    state.createUserMsg = { success: true, text: getters.getTranslate.USERS_CREATE_SUCCESS }

                } else {

                    state.createUserMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.createUserProcess = false

            } else {

                state.createUserMsg = { success: false, text: 'ETokenNotFound' }
            }
        },

        /**
         * readUsers
         * Получает информацию о пользователях
         * @param {Object} payload - параметры для фильтрации результата
         */
        async readUsers({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.usersProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/users.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.users = request
                    state.usersMsg = { success: true, text: '' }

                } else {

                    state.users = [],
                    state.usersMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.usersProcess = false

            } else {

                state.usersMsg = { success: false, text: 'ETokenNotFound' }
            }
        },

        /**
         * deleteUser
         * Удаляет пользователя c указанным логином
         * @param {String} payload - логин пользователя в системе 
         */
        async deleteUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.deleteUserProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/users.php?login=${payload}`, 
                    method: 'DELETE',
                    token
                })

                if(!request.error) {

                    state.deleteUserMsg = { success: true, text: getters.getTranslate.USERS_DELETE_SUCCESS }

                } else {

                    state.deleteUserMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.deleteUserProcess = false

            } else {

                state.deleteUserMsg = { success: false, text: 'ETokenNotFound' }
            }
        }
    },

    mutations: {

        setCreateUserMsg: (state, data) => state.createUserMsg = data,
        setUpdateUserMsg: (state, data) => state.updateUserMsg = data,
        setDeleteUserMsg: (state, data) => state.deleteUserMsg = data
    }
}