<template>
    <div class="page page--devices">
        <h1 class="page__header">{{ getTranslate.PAGE_DEVICES }}</h1>
        <KTADevices />
    </div>
</template>

<script>
import KTADevices from '@/components/KTADevices.vue'

import { mapGetters } from 'vuex'

export default {

    name: 'ViewDevices',

    components: {

        KTADevices
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>
