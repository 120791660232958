<template>
    <div class="page page--main">
        <KTAMap />
    </div>
</template>

<script>
import KTAMap from '@/components/KTAMap.vue'

export default {

    name: 'ViewMain',

    components: {

        KTAMap
    }
}
</script>
