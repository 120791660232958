import { createRouter, createWebHistory } from 'vue-router'

import * as bootstrap from 'bootstrap'
import store from '@/store/index.js'

import ViewAuth     from '@/views/ViewAuth.vue'
import ViewUsers    from '@/views/ViewUsers.vue'
import ViewUser     from '@/views/ViewUser.vue'
import ViewMain     from '@/views/ViewMain.vue'
import ViewDevices  from '@/views/ViewDevices.vue'
import ViewDevice   from '@/views/ViewDevice.vue'

const router = createRouter({

    history: createWebHistory(process.env.BASE_URL),

    routes: [

        {
            path: '/auth',
            name: 'auth',
            component: ViewAuth
        }, {
            path: '/users',
            name: 'users',
            component: ViewUsers,
            meta: {

                onlyAuth: true
            }
        }, {
            path: '/users/:id',
            name: 'user',
            props: true,
            component: ViewUser,
            meta: {

                onlyAuth: true
            }
        }, {
            path: '/',
            name: 'main',
            component: ViewMain,
            meta: {

                onlyAuth: true
            }
        }, {
            path: '/devices',
            name: 'devices',
            component: ViewDevices,
            meta: {

                onlyAuth: true
            }
        }, {
            path: '/devices/:id',
            name: 'device',
            props: true,
            component: ViewDevice,
            meta: {

                onlyAuth: true
            }
        }
    ]
})

router.beforeEach((to, from, next) => {

    const naviMenu = document.getElementById('navbarSupportedContent')

    if( naviMenu && naviMenu.classList.contains('show') ) {

        new bootstrap.Collapse(naviMenu, { hide: true })
    }

    if(to.fullPath == '/auth' && store.state.token.token) {

        next('/') 
        return
    }

    if(to.matched.some(record => record.meta.onlyAuth)) {

        if (store.state.token.token) {

            next() 
            return
        }

        next('/auth') 

    } else {

        next() 
    }
})

export default router
