export default {

    state: {

        track:          {},
        trackProcess:   false,
        trackMsg:       {}
    },

    actions: {

        /**
         * readTrack
         * 
         * Получает точки трека для указанного устройства по времени (с ... по ...)
         * 
         * @param {Object} payload - { deviceId: String, start: Date(), finish: Date() }
         */
        async readTrack({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.trackProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/track.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.track = request
                    state.trackMsg = { success: true, text: '' }

                } else {

                    state.trackMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.trackProcess = false

            } else {

                state.trackMsg = { success: false, text: 'ETokenNotFound' }
            }

            return state.track
        }
    },

    mutations: {

        setTrackMsg: (state, data) => state.trackMsg = data
    }
}