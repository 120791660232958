<template>

    <form class="kta-user" @submit.prevent="submitForm()">

        <div 
            class="kta-user__message alert alert-dismissible" 
            :class="{ 
                'alert-danger' : !userUpdateMsg.success, 
                'alert-success' : userUpdateMsg.success 
            }" 
            v-if="'success' in userUpdateMsg"
        >
            {{ userUpdateMsg.text }}
            <button type="button" class="btn-close" @click="setUpdateUserMsg({})"></button>
        </div>

        <div class="alert alert-danger alert-dismissible kta-devices__create-error" role="alert" v-if="formErrors.length">
            {{ getTranslate.FORM_ERROR_FIELDS }}: 
            <span v-for="(field, index) in formErrors" :key="index">
                {{ field }}<template v-if="index + 1 !== formErrors.length">, </template>
            </span>
            <button type="button" class="btn-close" @click="formErrors = []"></button>
        </div>

        <div class="my-4 kta-user__login">
            {{ getTranslate.USER_LOGIN }}: {{ user.login }}
        </div>

        <div class="my-4 kta-user__name">
            <template v-if="!editForm">
                {{ getTranslate.USER_NAME }}: {{ user.name }}
            </template>
            <template v-else>
                <label for="KTAUserName">{{ getTranslate.USER_NAME }}</label>
                <input 
                    type="text" 
                    id="KTAUserName" 
                    class="form-control" 
                    v-model="name"
                >
            </template>
        </div>

        <div class="my-4 kta-user__email">
            <template v-if="!editForm">
                {{ getTranslate.USER_EMAIL }}: {{ user.email }}
            </template>
            <template v-else>
                <label for="KTAUserEmail">{{ getTranslate.USER_EMAIL }}</label>
                <input 
                    type="text" 
                    id="KTAUserEmail" 
                    class="form-control" 
                    :class="{ 'is-invalid' : formInvalids.email }"
                    @input="delete formInvalids.email"
                    v-model="email"
                >
            </template>
        </div>

        <div class="my-4 kta-user__phone">
            <template v-if="!editForm">
                {{ getTranslate.USER_PHONE }}: {{ user.phone }}
            </template>
            <template v-else>
                <label for="KTAUserPhone">{{ getTranslate.USER_PHONE }}</label>
                <input 
                    type="text" 
                    id="KTAUserPhone" 
                    class="form-control" 
                    :class="{ 'is-invalid' : formInvalids.phone }"
                    @input="delete formInvalids.phone"
                    v-model="phone"
                >
            </template>
        </div>

        <div class="my-4 kta-user__created">
            {{ getTranslate.USER_CREATED }}: {{ user.created }}
        </div>

        <div class="my-4 btns">
            <template v-if="!editForm">
                <button type="button" class="btn btn-primary" @click="editForm = true">{{ getTranslate.BTN_EDIT }}</button>
            </template>
            <template v-else>
                <button type="button" class="btn btn-outline-primary me-4" @click="editForm = false">{{ getTranslate.BTN_CANCEL }}</button>
                <button type="submit" class="btn btn-primary">{{ getTranslate.BTN_SAVE }}</button>
            </template>
        </div>

    </form>

</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import checkValid from '@/helpers/checkValid'

export default {

    name: 'KTAUser',

    data() {

        return {

            login: null,
            name: null,
            email: null,
            phone: null,

            editForm: false,

            formInvalids: {},
            formErrors: [],

            checkValid
        }
    },

    props: {

        id: {

            type: String,
            required: true
        }
    },

    computed: {

        ...mapState({

            user:               state => state.user.user,
            userProcess:        state => state.user.userProcess,
            userMsg:            state => state.user.userMsg,

            userUpdateProcess:  state => state.user.userUpdateProcess,
            userUpdateMsg:      state => state.user.userUpdateMsg,
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readUser',
            'updateUser'
        ]),

        ...mapMutations([

            'setUpdateUserMsg'
        ]),

        submitForm() {

            this.formErrors = []
            this.formInvalids = {}

            let data = {
                id: this.id
            }

            if(this.login) {
                data.login = this.login
            } else {
                this.formInvalids.login = true
                this.formErrors.push(this.getTranslate.USER_LOGIN)
            }

            if(this.email && this.checkValid(this.email, 'email')) {
                data.email = this.email
            } else {
                this.formInvalids.email = true
                this.formErrors.push(this.getTranslate.USER_EMAIL)
            }

            if(this.phone) {
                if(this.checkValid(this.phone, 'phone')) {
                    data.phone = this.phone
                } else {
                    this.formInvalids.phone = true
                    this.formErrors.push(this.getTranslate.USER_PHONE)
                }
            }

            if(this.name) data.name = this.name

            if(this.formErrors.length == 0) {

                this.updateUser(data)
            }
        }
    },

    created() {

        this.readUser({ id: this.id }).then(user => {

            if(user.login) {

                this.$emit('userName', user.login)

                this.login  = user.login
                this.name   = user.name
                this.email  = user.email
                this.phone  = user.phone
            }
        })
    },

    watch: {

        editForm(mode) {

            if(mode) {

                this.setUpdateUserMsg({})

            } else {

                this.login  = this.user.login
                this.name   = this.user.name
                this.email  = this.user.email
                this.phone  = this.user.phone
            }
        },

        userUpdateMsg(msg) {

            if('success' in msg && msg.success) {

                this.editForm = false

                this.readUser({ id: this.id }).then(user => {

                    if(user.login) {

                        this.$emit('userName', user.login)

                        this.login  = user.login
                        this.name   = user.name
                        this.email  = user.email
                        this.phone  = user.phone
                    }
                })
            }
        }
    },

    emits: [

        'userName'
    ]
}
</script>