<template>

    <form class="kta-device-info" @submit.prevent="submitForm()">

        <div 
            v-if="'success' in deviceMsg && !deviceMsg.success"
            class="kta-device-info__message alert alert-danger my-4" 
        >
            {{ deviceMsg.text }}
        </div>

        <div 
            v-if="'success' in deviceUpdateMsg"
            class="kta-device-info__message alert alert-dismissible my-4" 
            :class="{ 
                'alert-danger' : !deviceUpdateMsg.success, 
                'alert-success' : deviceUpdateMsg.success 
            }" 
        >
            {{ deviceUpdateMsg.text }}
            <button type="button" class="btn-close" @click="setDeviceUpdateMsg({})"></button>
        </div>

        <div class="alert alert-danger alert-dismissible my-4" role="alert" v-if="formErrors.length">
            {{ getTranslate.FORM_ERROR_FIELDS }}: 
            <span v-for="(field, index) in formErrors" :key="index">
                {{ field }}<template v-if="index + 1 !== formErrors.length">, </template>
            </span>
            <button type="button" class="btn-close" @click="formErrors = []"></button>
        </div>

        <template v-if="'success' in deviceMsg && deviceMsg.success">
            <div class="my-4 kta-device-info__name">
                <template v-if="!editForm">
                    <div class="kta-device-info__name-value">
                        {{ device.name }}
                    </div>
                </template>
                <template v-else>
                    <label for="KTADeviceName" class="kta-device-info__name-label">{{ getTranslate.DEVICE_NAME }}</label>
                    <input 
                        type="text" 
                        id="KTADeviceName" 
                        class="form-control" 
                        v-model="name"
                    >
                </template>
            </div>
            <div class="my-4 kta-device-info__code">
                <template v-if="!editForm">
                    {{ getTranslate.DEVICE_CODE }}: {{ device.code }}
                </template>
                <template v-else>
                    <label for="KTADeviceCode">{{ getTranslate.DEVICE_CODE }}</label>
                    <input 
                        type="number" 
                        id="KTADeviceCode" 
                        class="form-control" 
                        v-model="code"
                    >
                </template>
            </div>
            <div class="my-4 kta-device-info__descr">
                <template v-if="!editForm">
                    {{ getTranslate.DEVICE_DESCR }}: {{ device.descr }}
                </template>
                <template v-else>
                    <label for="KTADeviceDescr">{{ getTranslate.DEVICE_DESCR }}</label>
                    <input 
                        type="text" 
                        id="KTADeviceDescr" 
                        class="form-control" 
                        :class="{ 'is-invalid' : formInvalids.descr }"
                        @input="delete formInvalids.descr"
                        v-model="descr"
                    >
                </template>
            </div>

            <div class="my-4 kta-device-info__isprivate">
                <template v-if="!editForm">
                    {{ getTranslate.DEVICE_ISPRIVATE }}: {{ isPrivate ? getTranslate.YES : getTranslate.NO }}
                </template>
                <template v-else>
                    <div class="form-check">
                        <label for="KTADeviceIsPrivate" class="form-check-label">{{ getTranslate.DEVICE_ISPRIVATE }}</label>
                        <input 
                            type="checkbox" 
                            id="KTADeviceIsPrivate" 
                            class="form-check-input" 
                            v-model="isPrivate"
                        >
                    </div>
                </template>
            </div>

            <div class="my-4 kta-device-info__created">
                {{ getTranslate.DEVICE_CREATED }}: {{ device.created }}
            </div>

            <a 
                href="#" 
                @click.prevent="showDeviceDelete = true"
            >
                {{ getTranslate.DEVICE_DELETE_LINK }}
            </a>

            <div class="my-4 btns">
                <template v-if="!editForm">
                    <button type="button" class="btn btn-primary" @click="editForm = true">{{ getTranslate.BTN_EDIT }}</button>
                </template>
                <template v-else>
                    <button type="button" class="btn btn-outline-primary me-4" @click="editForm = false">{{ getTranslate.BTN_CANCEL }}</button>
                    <button type="submit" class="btn btn-primary">{{ getTranslate.BTN_SAVE }}</button>
                </template>
            </div>

            <transition name="bounce">
                <Modal 
                    v-if="showDeviceDelete"
                    :header="getTranslate.DEVICE_DELETE_TITLE"
                    size="mdf"
                    @closeModal="showDeviceDelete = false"
                >
                    <template v-slot:body>

                        <form @submit.prevent="deleteDevice(id)" autocomplete="off">

                            <div class="alert alert-danger alert-dismissible kta-device-info__delete-error" role="alert" v-if="deviceDeleteMsg.success === false">
                                {{ deviceDeleteMsg.text }}
                                <button type="button" class="btn-close" @click="setDeviceDeleteMsg({})"></button>
                            </div>

                            <div class="kta-device-info__delete-descr">
                                {{ getTranslate.DEVICE_DELETE_DESCR }}
                            </div>

                            <div class="mt-4 btns">
                                <button type="button" class="btn btn-outline-secondary me-3" data-bs-dismiss="modal">{{ getTranslate.BTN_CANCEL }}</button>
                                <button type="submit" class="btn btn-danger" :disabled="deviceDeleteProcess">
                                    <template v-if="deviceDeleteProcess">
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </template>
                                    <template v-else>
                                        {{ getTranslate.BTN_DELETE }}
                                    </template>
                                </button>
                            </div>

                        </form>

                    </template>
                </Modal>
            </transition>

        </template>

    </form>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import Modal from '@/components/KTAModal.vue'

export default {

    name: 'KTADevice',

    data() {

        return {

            name:       null,
            code:       null,
            descr:      null,
            isPrivate:  false,

            editForm: false,

            showDeviceDelete: false,

            formInvalids: {},
            formErrors: []
        }
    },

    props: {

        deviceId: {

            type: String,
            required: true
        }
    },

    components: {

        Modal
    },

    computed: {

        ...mapState({

            device:               state => state.device.device,
            deviceProcess:        state => state.device.deviceProcess,
            deviceMsg:            state => state.device.deviceMsg,

            deviceUpdateProcess:  state => state.device.deviceUpdateProcess,
            deviceUpdateMsg:      state => state.device.deviceUpdateMsg,

            deviceDeleteProcess:  state => state.device.deviceDeleteProcess,
            deviceDeleteMsg:      state => state.device.deviceDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readDevice',
            'updateDevice',
            'deleteDevice'
        ]),

        ...mapMutations([

            'setDeviceMsg',
            'setDeviceUpdateMsg',
            'setDeviceDeleteMsg'
        ]),

        submitForm() {

            this.formErrors = []
            this.formInvalids = {}

            let data = {
                id: this.deviceId,
                isPrivate: this.isPrivate
            }

            if(this.name) {
                data.name = this.name
            } else {
                this.formInvalids.name = true
                this.formErrors.push(this.getTranslate.DEVICE_NAME)
            }

            if(this.descr)  data.descr  = this.descr
            if(this.code)   data.code   = this.code

            if(this.formErrors.length == 0) {
                this.updateDevice(data)
            }
        }
    },

    created() {

        this.readDevice({ id: this.deviceId }).then(device => {

            if(device.id) {

                this.$emit('deviceName', device.name)

                this.name       = device.name
                this.descr      = device.descr
                this.code       = device.code
                this.isPrivate  = Boolean(Number(device.is_private))
            }
        })
    },

    beforeUnmount() {

        this.setDeviceMsg({})
    },

    watch: {

        editForm(mode) {

            if(mode) {

                this.setDeviceUpdateMsg({})

            } else {

                this.name       = this.device.name
                this.code       = this.device.code
                this.descr      = this.device.descr
                this.isPrivate  = Boolean(Number(this.device.is_private))
            }
        },

        deviceUpdateMsg(msg) {

            if('success' in msg && msg.success) {

                this.editForm = false

                this.readDevice({ id: this.deviceId }).then(device => {

                    if(device.id) {

                        this.$emit('deviceName', device.name)

                        this.name       = device.name
                        this.code       = device.code
                        this.descr      = device.descr
                        this.isPrivate  = Boolean(Number(device.is_private))
                    }
                })
            }
        },

        deviceDeleteMsg(msg) {

            if('success' in msg && msg.success) {

                this.showDeviceDelete = false
                this.$router.push('/devices')
            }
        }
    },

    emits: [

        'deviceName'
    ]
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/device-info.scss';
</style>