<template>

    <KTAHeader v-if="token" />
    <router-view />

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import KTAHeader from '@/components/KTAHeader.vue'

export default {

    name: 'App',

    components: {

        KTAHeader
    },

    computed: {

        ...mapState({

            token: state => state.token.token
        })
    },

    methods: {

        ...mapActions([

            'addToken',
            'updateLanguage'
        ]),

        ...mapMutations([

            'setServerHttp',
            'setServerWs',
            'setServerMapTiles'
        ])
    },

    created() {

        const BASE_URL = window.location.host
        const BASE_PROTOCOL = window.location.protocol

        if(process.env.NODE_ENV == 'development') {

            this.setServerHttp("https://kta.wr-team.ru/api/1")
            this.setServerWs("ws://kta.wr-team.ru/api/1")
            this.setServerMapTiles('http://{s}.tile.osm.org/{z}/{x}/{y}.png')

        } else {

            this.setServerHttp( BASE_PROTOCOL + '//' + BASE_URL + '/api/1' )
            this.setServerWs( ( BASE_PROTOCOL == 'http:' ? 'ws' : 'wss') + '://' + BASE_URL + '/api/1')
            this.setServerMapTiles(BASE_PROTOCOL + '//' + '{s}.tile.osm.org/{z}/{x}/{y}.png')
        }

        this.addToken()
        this.updateLanguage()
    },

    watch: {

        token(token) {

            if(!token) {

                window.reload()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/layout.scss';
</style>
