<template>

    <div class="kta-device-map">

        <div id="KTAMap" class="kta-device-map__container"></div>

        <div class="kta-device-map__bottom-right kta-device-map__bottom-right--horizontal">

            <div class="kta-device-map__btn kta-device-map__btn--lg" @click="deleteTrack()" v-if="trackLine">
                <span class="material-icons kta-device-map__btn-icon">
                    clear
                </span>
            </div>

            <div class="kta-device-map__btn kta-device-map__btn--lg" @click="showTrackRequest = true">
                <span class="material-icons kta-device-map__btn-icon">
                    timeline
                </span>
            </div>

        </div>

        <transition name="bounce">
            <Modal 
                v-if="showTrackRequest"
                :header="getTranslate.TRACK_TITLE"
                size="mdf"
                @closeModal="showTrackRequest = false"
            >
                <template v-slot:body>

                    <KTADeviceTrack :deviceId="deviceId" @closeModal="showTrackRequest = false" />

                </template>
            </Modal>
        </transition>

    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import Leaflet from 'leaflet'
import Modal from '@/components/KTAModal'
import KTADeviceTrack from '@/components/device/KTADeviceTrack'

export default {

    name: 'KTAMap',

    data() {

        return {

            map: {},
            mapCenter: [0, 0],
            trackLine: null,
            showTrackRequest: false
        }
    },

    props: {

        deviceId: {

            type: String,
            required: false
        }
    },

    components: {

        Modal,
        KTADeviceTrack
    },

    computed: {

        ...mapState({

            serverMapTiles: state => state.config.serverMapTiles,
            points: state => state.points.points,
            track: state => state.track.track
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'readPoints'
        ]),

        deleteTrack() {

            if(this.trackLine) {

                this.map.removeLayer(this.trackLine)
                this.trackLine = null
            }
        }
    },

    mounted() {

        const self = this

        if(localStorage.getItem('KTA_MAP_CENTER')) {

            const coords = JSON.parse(localStorage.getItem('KTA_MAP_CENTER'))

            this.mapCenter = [ coords.lat, coords.lng ]

        } else {

            this.mapCenter = [ 0, 0 ]
        }

        self.map = Leaflet.map('KTAMap', {
            center: this.mapCenter,
            zoomControl: false,
            zoom: localStorage.getItem('KTA_MAP_ZOOM_LVL'),
            maxZoom: 19,
            attributionControl: false
        })

        // Сохраняем значение в памяти браузера при изменении масштаба карты
        self.map.on('zoomend', function() {

            localStorage.setItem('KTA_MAP_ZOOM_LVL', self.map._zoom)
        })

        // Сохраняем участок с видимой областью карты
        self.map.on('moveend', () => {

            localStorage.setItem('KTA_MAP_CENTER', JSON.stringify(self.map.getCenter()))
        })

        // Добавляем управление зумом
        Leaflet.control.zoom({ position: 'bottomright' }).addTo(self.map)

        // Удаляем копирайт Leaflet
        Leaflet.control.attribution().remove()

        // Основная карта
        Leaflet.tileLayer(this.serverMapTiles, {
            minZoom: 5,
            maxZoom: 19,
            noWrap: true
        }).addTo(self.map)

        self.readPoints({ deviceId: self.deviceId, last: true }).then(

            point => {

                if(point.lat && point.lng) {

                    const iconMarker = Leaflet.icon({
                        iconUrl:        require('@/assets/images/icons/icon-map-active.svg'),
                        iconRetinaUrl:  require('@/assets/images/icons/icon-map-active.svg'),
                        iconSize:       [25, 25], // Размер иконки маркера
                        iconAnchor:     [12, 25], // Сдвиг иконки маркера относительно lat/lng
                        shadowSize:     [0, 0]    // Размер тени иконки маркера
                    })

                    const popup = Leaflet.popup()

                    popup
                        .setLatLng({

                            lat: point.lat,
                            lng: point.lng
                        })

                    let popupContent = `

                        <div class="kta-device-map__popup-time">
                            <div class="kta-device-map__popup-time-title">
                                Регистрация: 
                            </div>
                            <div class="kta-device-map__popup-time-value">
                                ${new Date(point.created).toLocaleString() || '—'}
                            </div>
                        </div>
                    `

                    popup.setContent(popupContent)

                    const marker = Leaflet.marker([point.lat, point.lng], { icon: iconMarker })

                    marker.bindPopup(popup, {
                        'minWidth': '250',
                        'className': 'zai-map__popup'
                    })

                    self.map.addLayer(marker)

                    self.map.flyTo([point.lat, point.lng]);
                }
            }
        )
    },

    watch: {

        track(track) {

            if(track.length) {

                if(this.trackLine) {

                    this.map.removeLayer(this.trackLine)
                    this.trackLine = null
                }

                const trackPoints = []

                track.map( point => {

                    if(point.lat !== '-1.000000' && point.lng !== '-1.000000') {

                        trackPoints.push([point.lat, point.lng])
                    }
                })

                this.trackLine = Leaflet.polyline(trackPoints, {color: 'red'}).addTo(this.map);

                this.map.flyToBounds(this.trackLine.getBounds());
            }
        }
    }
}
</script>

<style lang="scss">
@import '~@/../node_modules/leaflet/dist/leaflet.css';
@import '~@/../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '@/assets/styles/scss/components/device-map.scss';
</style>