export default {

    actions: {

        async fetchRequest({dispatch}, payload) {

            let parameters = {

                method: payload.method,
                cache: 'no-cache',
                referrerPolicy: 'origin-when-cross-origin',
                headers: new Headers()
            }

            if(payload.token) {

                parameters.headers.append('Authorization', 'Bearer ' + payload.token)
            }

            if(payload.data) {

                if(payload.type) {

                    payload.type == 'json' ?
                        parameters.body = JSON.stringify(payload.data) :
                        parameters.body = payload.data

                } else {

                    parameters.body = JSON.stringify(payload.data)
                }
            }

            try {

                const request = await fetch(payload.address, parameters)

                const response = await request.text()

                const result = JSON.parse( response )

                if(request.ok) {

                    if(!result.error) {

                        return result

                    } else {

                        return { error: result.error }
                    }

                } else if(

                    result.error == 'ETokenParseError' || 
                    result.error == 'EUnauthRequest' || 
                    result.error == 'ETokenInvalid' || 
                    result.error == 'ETokenExpired'

                ) {

                    dispatch('logout')
                    return { error: result.error }

                } else if(result.error) {

                    return { error: result.error }

                } else {

                    return { error: 'EUnknown' }
                }

            } catch {

                return { error: 'EConnection' }
            }
        },

        logout() {

            localStorage.removeItem('KTA_TOKEN')
            document.location.reload()
        }
    }
}