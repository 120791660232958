<template>
    <div class="kta-devices">

        <div 
            class="alert alert-dismissible my-4"
            :class="{
                'alert-success': createDeviceMsg.success,
                'alert-danger': !createDeviceMsg.success
            }"
            v-if="'success' in createDeviceMsg"
        >
            {{ createDeviceMsg.text }}
            <button type="button" class="btn-close" @click="setDeviceCreateMsg({})"></button>
        </div>

        <div 
            v-if="'success' in deviceDeleteMsg"
            class="alert alert-success alert-dismissible my-4"
        >
            {{ deviceDeleteMsg.text }}
            <button type="button" class="btn-close" @click="setDeviceDeleteMsg({})"></button>
        </div>

        <ol class="list-group list-group-numbered">
            <li class="list-group-item d-flex justify-content-between align-items-start" v-for="device of devices" :key="device.id">
                <div class="ms-2 me-auto">
                    <div class="fw-bold"><router-link :to="'devices/' + device.id">{{ device.name }}</router-link></div>
                    {{ device.descr || '&mdash;' }}
                </div>
                <span 
                    class="badge rounded-pill"
                    :class="{ 
                        'text-bg-success' : isOnline(device.activity),
                        'text-bg-danger' : !isOnline(device.activity)
                    }"
                >
                    {{ 
                        isOnline(device.activity) ? 
                            getTranslate.STATUS_ONLINE : 
                            getTranslate.STATUS_OFFLINE 
                    }}
                </span>
            </li>
        </ol>

        <div class="btns">
            <button type="button" class="my-4 mx-3 btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalDeviceCreate">
                {{ getTranslate.BTN_ADD }}
            </button>
        </div>

        <div class="kta-devices__create modal fade" id="modalDeviceCreate" tabindex="-1" aria-labelledby="modalDeviceCreateLabel" aria-hidden="true">
            <div class="modal-dialog">
                <form class="modal-content" @submit.prevent="submitForm()">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalDeviceCreateLabel">{{ getTranslate.DEVICES_CREATE_TITLE }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger alert-dismissible kta-devices__create-error" role="alert" v-if="formErrors.length">
                            {{ getTranslate.FORM_ERROR_FIELDS }}: 
                            <span v-for="(field, index) in formErrors" :key="index">
                                {{ field }}<template v-if="index + 1 !== formErrors.length">, </template>
                            </span>
                            <button type="button" class="btn-close" @click="formErrors = []"></button>
                        </div>
                        <div class="mb-3">
                            <label for="KTADeviceCreateName" class="form-label">{{ getTranslate.DEVICES_CREATE_NAME }}</label>
                            <input 
                                type="text" 
                                class="form-control"
                                :class="{ 'is-invalid': formInvalids.KTADeviceCreateName }" 
                                id="KTADeviceCreateName" 
                                v-model="name"
                                @input="delete formInvalids.KTADeviceCreateName"
                            >
                        </div>
                        <div class="mb-3">
                            <label for="KTADeviceCreateCode" class="form-label">{{ getTranslate.DEVICES_CREATE_CODE }}</label>
                            <input 
                                type="number" 
                                class="form-control" 
                                :class="{ 'is-invalid': formInvalids.KTADeviceCreateCode }" 
                                id="KTADeviceCreateCode" 
                                v-model="code"
                                @input="delete formInvalids.KTADeviceCreateCode"
                            >
                        </div>
                        <div class="mb-3">
                            <label for="KTADeviceCreateDescr" class="form-label">{{ getTranslate.DEVICES_CREATE_DESCR }}</label>
                            <input 
                                type="text" 
                                class="form-control" 
                                id="KTADeviceCreateDescr" 
                                v-model="descr"
                            >
                        </div>
                        <div class="mb-3">
                            <label for="KTADeviceCreateOwner" class="form-label">{{ getTranslate.DEVICES_CREATE_OWNER }}</label>
                            <select 
                                id="KTADeviceCreateOwner" 
                                class="form-control" 
                                v-model="owner"
                            >
                                <option value="" selected>...</option>
                            </select>
                        </div>
                        <div class="mb-3 form-check">
                            <input 
                                type="checkbox" 
                                class="form-check-input" 
                                id="KTADeviceCreatePrivate" 
                                v-model="isPrivate"
                            >
                            <label class="form-check-label" for="KTADeviceCreatePrivate">{{ getTranslate.DEVICES_CREATE_PRIVATE }}</label>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ getTranslate.BTN_CANCEL }}</button>
                        <button type="submit" class="btn btn-primary">{{ getTranslate.BTN_ADD }}</button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import * as bootstrap from 'bootstrap' 
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: "KTADevices",

    data() {

        return {

            name:       null,
            code:       null,
            descr:      null,
            owner:      null,
            isPrivate:  false,

            formInvalids: {},
            formErrors: []
        }
    },

    computed: {

        ...mapState({

            devices:                state => state.devices.devices,
            devicesProcess:         state => state.devices.devicesProcess,
            devicesMsg:             state => state.devices.devicesMsg,

            createDeviceProcess:    state => state.devices.createDeviceProcess,
            createDeviceMsg:        state => state.devices.createDeviceMsg,

            deviceDeleteMsg:        state => state.device.deviceDeleteMsg
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'createDevice',
            'readDevices'
        ]),

        ...mapMutations([

            'setDeviceCreateMsg',
            'setDeviceDeleteMsg'
        ]),

        isOnline(date) {

            let online = false

            if(date && !isNaN(Date.parse(date))) {

                const lastPoint = new Date(date).getTime()
                const curTime   = Date.now()

                online = curTime - lastPoint <= 18000 * 1000 ? true : false // 18000 * 1000 мс - 5 мин
            }

            return online
        },

        submitForm() {

            let data = {

                isPrivate: this.isPrivate
            }

            this.formInvalids = {}
            this.formErrors = []

            if(this.name && this.name.length > 3) {
                data.name = this.name
            } else {
                this.formInvalids.KTADeviceCreateName = true
                this.formErrors.push(this.getTranslate.DEVICES_CREATE_NAME)
            }

            if(this.code) {
                data.code = this.code
            } else {
                this.formInvalids.KTADeviceCreateCode = true
                this.formErrors.push(this.getTranslate.DEVICES_CREATE_CODE)
            }

            if(this.descr)  data.descr  = this.descr
            if(this.owner)  data.owner  = this.owner

            if(this.formErrors.length == 0) {

                this.modalDeviceCreate.hide()
                this.createDevice(data)
            }
        }
    },

    mounted() {

        this.modalDeviceCreate = new bootstrap.Modal('#modalDeviceCreate')

        const modalDeviceCreateDOM = document.getElementById('modalDeviceCreate')
        modalDeviceCreateDOM.addEventListener('hidden.bs.modal', () => {

            this.formInvalids = {}
            this.formErrors = []
        })

        this.readDevices()
    },

    beforeUnmount() {

        this.setDeviceCreateMsg({})
        this.setDeviceDeleteMsg({})
    },

    watch: {

        createDeviceMsg(msg) {

            if(msg && msg.success) {

                this.readDevices()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/devices';
</style>