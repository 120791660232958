<template>
    <div class="page page--device">
        <KTADevice :id="id" @deviceName="setPageTitle($event)" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import KTADevice from '@/components/KTADevice.vue'

export default {

    name: 'ViewDevice',

    data() {

        return {

            pageHeader: null
        }
    },

    props: {

        id: {

            type: String,
            required: true
        }
    },

    components: {

        KTADevice
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        setPageTitle(event) {

            this.pageHeader = event
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/views/device';
</style>