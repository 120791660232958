export default {

    state: {

        lang: 'RU'
    },

    actions: {

        updateLanguage({state}, payload) {

            if(payload) {

                localStorage.setItem('KTA_LANG', payload)
                state.lang = payload

            } else {

                if(localStorage.getItem('KTA_LANG')) {

                    state.lang = localStorage.getItem('KTA_LANG')
                }
            }

            // Меняем HTML lang при определении/переключении языка
            document.getElementsByTagName('html')[0].setAttribute('lang', state.lang.toLowerCase())
        }
    }
}