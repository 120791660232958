/**
 * checkValid
 * 
 * Проверяет соответствие значения value заданной проверке mode
 * 
 * @param {*} value 
 * @param {String} mode - тип проверки [email, phone, number]
 * @returns 
 */
export default function checkValid(value, mode) {

    let valid = true

    switch(mode) {

        case 'email':   valid = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/.test(value)
            break

        case 'phone':   valid = /^[\\+]?[\s\d\\)\\(-]{3,20}$/im.test(value)
            break

        case 'number':  valid = !isNaN(Number(value))
            break
    }

    return valid
}