<template>
    <div class="page page--user">
        <h1>{{ pageHeader || getTranslate.USER_TITLE }}</h1>
        <KTAUser :id="id" @userName="setPageTitle($event)" />
    </div>
</template>

<script>
import KTAUser from '@/components/KTAUser.vue'
import { mapGetters } from 'vuex'

export default {

    name: 'ViewUser',

    data() {

        return {

            pageHeader: null
        }
    },

    props: {

        id: {

            type: String,
            required: true
        }
    },

    components: {

        KTAUser
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        setPageTitle(event) {

            this.pageHeader = event
        }
    }
}
</script>