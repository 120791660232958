export default {

    state: {

        points:             [],
        pointsProcess:      false,
        pointsMsg:          {},

        setPointProcess:    false,
        setPointMsg:        {}
    },

    actions: {

        async readPoints({rootState, state, dispatch}, payload) {

            const token = rootState.token.token
            let result = {}

            if(token) {

                state.pointsProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/get_points.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.points = request
                    state.pointsMsg = { success: true, text: '' }
                    result = state.points

                } else {

                    state.points = []
                    state.pointsMsg = { success: false, text: request.error }
                }

                state.pointsProcess = false

            } else {

                state.pointsMsg = { success: false, text: 'EToken' }
            }

            return result
        },

        async setPoint({rootState, state, dispatch}, payload) {

            state.setPointProcess = true

            const request = await dispatch('fetchRequest', {

                address: `${rootState.config.serverHttp}/set_points.php`, 
                method: 'POST',
                payload
            })

            if(!request.error) {

                state.setPointMsg = { success: true, text: '' }

            } else {

                state.setPointMsg = { success: false, text: request.error }
            }

            state.setPointProcess = false
        }
    }
}