export default {

    state: {

        devices:                [],
        devicesProcess:         false,
        devicesMsg:             {},

        createDeviceProcess:    false,
        createDeviceMsg:        {},

        deleteDeviceProcess:    false,
        deleteDeviceMsg:        {}
    },

    actions: {

        async createDevice({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.createDeviceProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/devices.php`, 
                    method: 'POST',
                    data: payload,
                    token
                })

                if(!request.error) {

                    state.createDeviceMsg = { success: true, text: getters.getTranslate.DEVICES_CREATE_SUCCESS }

                } else {

                    state.createDeviceMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.createDeviceProcess = false

            } else {

                state.createDeviceMsg = { success: false, text: 'ETokenNotFound' }
            }
        },

        async readDevices({rootState, state, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.devicesProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        if(typeof payload[param] == 'object' && payload[param].length) {

                            payload.vin_array.map( el => {

                                params.length ?
                                    params += '&' + param + '=' + el :
                                    params = '?' + param + '=' + el
                            })

                        } else {

                            params.length ?
                                params += '&' + param + '=' + payload[param] :
                                params = '?' + param + '=' + payload[param]
                        }
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/devices.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.devices = request
                    state.devicesMsg = { success: true, text: '' }

                } else {

                    state.devices = [],
                    state.devicesMsg = { success: false, text: request.error }
                }

                state.devicesProcess = false

            } else {

                state.devicesMsg = { success: false, text: 'ETokenNotFound' }
            }
        }
    },

    mutations: {

        setDeviceCreateMsg: (state, data) => state.createDeviceMsg = data,
    }
}