export default {

    state: {

        user:               {},
        userProcess:        false,
        userMsg:            {},

        userUpdateProcess:  false,
        userUpdateMsg:      {}
    },

    actions: {

        /**
         * readUser
         * 
         * Получает информацию о пользователе по ID
         * 
         * @param {Object} payload - параметры для выборки и фильтрации результата
         */
        async readUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.userProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/users.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.user = request
                    state.userMsg = { success: true, text: '' }

                } else {

                    state.userMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.userProcess = false

            } else {

                state.userMsg = { success: false, text: 'ETokenNotFound' }
            }

            return state.user
        },

        /**
         * updateUser
         * 
         * Обновляет информацию о пользователе
         * 
         * @param {Object} payload - поля формы редактирования пользователя
         */
        async updateUser({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.userUpdateProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/users.php`, 
                    method: 'PUT',
                    data: payload,
                    token
                })

                if(!request.error) {

                    state.userUpdateMsg = { success: true, text: getters.getTranslate.USER_UPDATE_SUCCESS }

                } else {

                    state.userUpdateMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.userUpdateProcess = false

            } else {

                state.userUpdateMsg = { success: false, text: 'ETokenNotFound' }
            }
        }
    },

    mutations: {

        setUpdateUserMsg: (state, data) => state.userUpdateMsg = data
    }
}