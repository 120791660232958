<template>
    <div class="kta-users">

        <div 
            class="kta-users__message alert alert-dismissible"
            :class="{
                'alert-success': createUserMsg.success,
                'alert-danger': !createUserMsg.success
            }"
            v-if="Object.keys(createUserMsg).length"
        >
            {{ createUserMsg.text }}
            <button type="button" class="btn-close" @click="setCreateUserMsg({})"></button>
        </div>

        <div 
            class="alert alert-dismissible alert-success"
            v-if="deleteUserMsg.success"
        >
            {{ deleteUserMsg.text }}
            <button type="button" class="btn-close" @click="setDeleteUserMsg({})"></button>
        </div>

        <div 
            class="alert alert-danger"
            v-if="'success' in usersMsg && !usersMsg.success"
        >
            {{ usersMsg.text }}
        </div>

        <ol class="list-group list-group-numbered">
            <li class="list-group-item d-flex justify-content-between align-items-start" v-for="user of users" :key="user.id">
                <div class="ms-2 me-auto">
                    <div class="fw-bold">
                        <router-link class="kta-users__user-login" :to="'/users/' + user.id">
                            {{ user.login }}
                        </router-link> 
                        <span class="kta-users__user-name">{{ user.name }}</span>
                    </div>
                    <div class="kta-users__user-email">
                        {{ user.email || '&mdash;' }}
                    </div>
                </div>
                <button 
                    type="button" 
                    class="btn btn-outline-secondary btn-sm"
                    data-bs-toggle="modal" 
                    data-bs-target="#modalUserDelete"
                    @click="deleteUserLogin = user.login"
                >
                    <template v-if="deleteUserProcess">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </template>
                    <template v-else>
                        {{ getTranslate.BTN_DELETE }}
                    </template>
                </button>
            </li>
        </ol>

        <div class="btns">
            <button type="button" class="my-4 mx-3 btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalUserCreate">
                {{ getTranslate.BTN_ADD }}
            </button>
        </div>

        <div class="kta-devices__create modal fade" id="modalUserCreate" tabindex="-1" aria-labelledby="modalUserCreateLabel" aria-hidden="true">
            <div class="modal-dialog">
                <form class="modal-content" @submit.prevent="submitForm()" autocomplete="off">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalUserCreateLabel">{{ getTranslate.USERS_CREATE_TITLE }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger alert-dismissible kta-devices__create-error" role="alert" v-if="formErrors.length">
                            {{ getTranslate.FORM_ERROR_FIELDS }}: 
                            <span v-for="(field, index) in formErrors" :key="index">
                                {{ field }}<template v-if="index + 1 !== formErrors.length">, </template>
                            </span>
                            <button type="button" class="btn-close" @click="formErrors = []"></button>
                        </div>
                        <div class="mb-3">
                            <input 
                                :placeholder="getTranslate.USERS_CREATE_LOGIN"
                                type="text" 
                                class="form-control"
                                :class="{ 'is-invalid': formInvalids.KTAUserCreateLogin }" 
                                id="KTAUserCreateLogin" 
                                v-model="login"
                                @input="delete formInvalids.KTAUserCreateLogin"
                            >
                        </div>
                        <div class="mb-3">
                            <div class="input">
                                <input 
                                    :placeholder="getTranslate.USERS_CREATE_PASS"
                                    :type="showPassword ? 'text' : 'password'" 
                                    autocomplete="off"
                                    class="form-control"
                                    :class="{ 'is-invalid': formInvalids.KTAUserCreatePassword }" 
                                    id="KTAUserCreatePassword" 
                                    v-model="password"
                                    @input="delete formInvalids.KTAUserCreatePassword"
                                >
                                <div class="input__icon">
                                    <a href="#" @click="showPassword = !showPassword" class="input__icon-link">
                                        <span class="material-icons">
                                            {{ showPassword ? 'visibility_off' : 'visibility' }}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <input 
                                :placeholder="getTranslate.USERS_CREATE_PASS_REPEAT"
                                :type="showPassword ? 'text' : 'password'" 
                                autocomplete="off"
                                class="form-control"
                                :class="{ 'is-invalid': formInvalids.KTAUserCreatePasswordRepeat }" 
                                id="KTAUserCreatePasswordRepeat" 
                                v-model="passwordRepeat"
                                @input="delete formInvalids.KTAUserCreatePasswordRepeat"
                            >
                        </div>
                        <div class="mb-3">
                            <input 
                                :placeholder="getTranslate.USERS_CREATE_NAME"
                                type="text" 
                                class="form-control"
                                id="KTAUserCreateName" 
                                v-model="name"
                            >
                        </div>
                        <div class="mb-3">
                            <input 
                                :placeholder="getTranslate.USERS_CREATE_EMAIL"
                                type="text" 
                                class="form-control" 
                                :class="{ 'is-invalid': formInvalids.KTAUserCreateEmail }" 
                                id="KTAUserCreateEmail" 
                                v-model="email"
                                @input="delete formInvalids.KTAUserCreateEmail"
                            >
                        </div>
                        <div class="mb-3">
                            <input 
                                :placeholder="getTranslate.USERS_CREATE_PHONE"
                                type="text" 
                                class="form-control" 
                                id="KTAUserCreatePhone" 
                                v-model="phone"
                            >
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ getTranslate.BTN_CANCEL }}</button>
                        <button type="submit" class="btn btn-primary" :disabled="createUserProcess">
                            <template v-if="createUserProcess">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </template>
                            <template v-else>
                                {{ getTranslate.BTN_ADD }}
                            </template>
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="kta-users__delete modal fade" id="modalUserDelete" tabindex="-1" aria-labelledby="modalUserDeleteLabel" aria-hidden="true">
            <div class="modal-dialog">
                <form class="modal-content" @submit.prevent="deleteUser(deleteUserLogin)" autocomplete="off">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="modalUserDeleteLabel">{{ getTranslate.USERS_DELETE_TITLE }}</h1>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="deleteUserLogin = null, setDeleteUserMsg({})"></button>
                    </div>
                    <div class="modal-body">
                        <div class="alert alert-danger alert-dismissible kta-users__delete-error" role="alert" v-if="deleteUserMsg.success === false">
                            {{ deleteUserMsg.text }}
                            <button type="button" class="btn-close" @click="setDeleteUserMsg({})"></button>
                        </div>
                        <div class="my-2">
                            {{ getTranslate.USERS_DELETE_DESCR }}
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">{{ getTranslate.BTN_CANCEL }}</button>
                        <button type="submit" class="btn btn-danger" :disabled="deleteUserProcess">
                            <template v-if="deleteUserProcess">
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            </template>
                            <template v-else>
                                {{ getTranslate.BTN_DELETE }}
                            </template>
                        </button>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import * as bootstrap from 'bootstrap' 
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {

    name: "KTADevices",

    data() {

        return {

            formInvalids: {},
            formErrors: [],

            login: null,
            password: null,
            passwordRepeat: null,
            name: null,
            email: null,
            phone: null,

            showPassword: false,

            deleteUserLogin: null
        }
    },

    computed: {

        ...mapState({

            users: state => state.users.users,
            usersProcess: state => state.users.usersProcess,
            usersMsg: state => state.users.usersMsg,

            createUserProcess: state => state.users.createUserProcess,
            createUserMsg: state => state.users.createUserMsg,

            deleteUserProcess: state => state.users.deleteUserProcess,
            deleteUserMsg: state => state.users.deleteUserMsg,
        }),

        ...mapGetters([

            'getTranslate'
        ])
    },

    methods: {

        ...mapActions([

            'createUser',
            'readUsers',
            'deleteUser'
        ]),

        ...mapMutations([

            'setCreateUserMsg',
            'setDeleteUserMsg'
        ]),

        submitForm() {

            let data = {}

            this.formInvalids = {}
            this.formErrors = []

            if(this.login) {
                data.login = this.login
            } else {
                this.formInvalids.KTAUserCreateLogin = true
                this.formErrors.push(this.getTranslate['USERS_CREATE_LOGIN'])
            }

            if(this.password) {
                data.password = this.password
            } else {
                this.formInvalids.KTAUserCreatePassword = true
                this.formErrors.push(this.getTranslate['USERS_CREATE_PASS'])
            }

            if(!this.passwordRepeat || this.passwordRepeat !== this.password) {
                this.formInvalids.KTAUserCreatePasswordRepeat = true
                this.formErrors.push(this.getTranslate['USERS_CREATE_PASS_REPEAT'])
            }

            if(this.email) {
                data.email = this.email
            } else {
                this.formInvalids.KTAUserCreateEmail = true
                this.formErrors.push(this.getTranslate['USERS_CREATE_EMAIL'])
            }

            if(this.name) data.name = this.name
            if(this.phone) data.phone = this.phone

            if(this.formErrors.length == 0) {

                this.createUser(data)
            }
        }
    },

    mounted() {

        this.modalUserCreate = new bootstrap.Modal('#modalUserCreate')

        const modalUserCreateDOM = document.getElementById('modalUserCreate')
        modalUserCreateDOM.addEventListener('hidden.bs.modal', () => {

            this.formInvalids = {}
            this.formErrors = []
        })

        this.modalUserDelete = new bootstrap.Modal('#modalUserDelete')

        this.readUsers()
    },

    watch: {

        createUserMsg(msg) {

            if(msg && msg.success) {

                this.modalUserCreate.hide()
                this.readUsers()
            }
        },

        deleteUserMsg(msg) {

            if(msg && msg.success) {

                this.modalUserDelete.hide()
                this.readUsers()
            }
        }
    }
}
</script>

<style lang="scss">
@import '@/assets/styles/scss/components/users';
</style>