<template>

    <a 
        class="nav-link dropdown-toggle" 
        href="#" 
        role="button" 
        data-bs-toggle="dropdown" 
        @click="showLangList = !showLangList"
    >
        {{ lang }}
    </a>

    <ul class="dropdown-menu">

        <template v-for="(language, index) in languages" :key="index">

            <li 
                class="dropdown-item"
                v-if="language.code != currentLanguage.code"
                :data-code="language.code" 
                @click="changeLang($event)"
            >
                {{ language.code }}
            </li>

        </template>

    </ul>

</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'

export default {

    name: 'ADTLanguage',

    data() {

        return {

            languages: [

                { code: 'RU' },
                { code: 'EN' }
            ],

            currentLanguage: {

                code: null,
            },

            showLangList: false
        }
    },

    computed: {

        ...mapState({

            lang: state => state.language.lang
        }),

        ...mapGetters([

            'getTranslate',
            'getLang'
        ])
    },

    methods: {

        ...mapActions([

            'updateLanguage'
        ]),

        changeLang(event) {

            let code = event.target.dataset.code
            this.currentLanguage.code = this.lang
            this.showLangList = false
            this.updateLanguage(code)
        }
    },

    mounted() {

        this.currentLanguage.code = this.lang
    },

    watch: {

        lang(code) {

            this.currentLanguage.code = code
        }
    }
}
</script>

<style lang="scss">
// @import '@/assets/styles/scss/components/language.scss';
</style>