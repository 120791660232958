export default {

    state: {

        RU: {

            APP_NAME: 'KAN Telematic Application',

            YES: 'Да',
            NO: 'Нет',

            MENU_MAIN: 'Главная',
            MENU_USERS: 'Пользователи',
            MENU_DEVICES: 'Устройства',
            MENU_LOGOUT: 'Выйти',

            BTN_ADD: 'Добавить',
            BTN_CANCEL: 'Отмена',
            BTN_DELETE: 'Удалить',
            BTN_EDIT: 'Редактировать',
            BTN_SAVE: 'Сохранить',
            BTN_REQUEST: 'Запросить',

            PAGE_DEVICES: 'Устройства',
            PAGE_USERS: 'Пользователи',

            FORM_SHOW_PASSWORD: 'Показать пароль',
            FORM_HIDE_PASSWORD: 'Скрыть пароль',

            STATUS_ONLINE: 'На связи',
            STATUS_OFFLINE: 'Нет связи',

            DEVICES_CREATE_TITLE: 'Новое устройство',
            DEVICES_CREATE_NAME: 'Имя',
            DEVICES_CREATE_CODE: 'Уникальный код',
            DEVICES_CREATE_DESCR: 'Описание',
            DEVICES_CREATE_OWNER: 'Владелец',
            DEVICES_CREATE_PRIVATE: 'Приватное',
            DEVICES_CREATE_SUCCESS: 'Новое устройство добавлено', 
            DEVICES_UPDATE_SUCCESS: 'Информация об устройстве обновлена',

            DEVICE_TITLE: 'Устройство',
            DEVICE_NAME: 'Название',
            DEVICE_CODE: 'Уникальный код', 
            DEVICE_DESCR: 'Описание',
            DEVICE_ISPRIVATE: 'Приватное',
            DEVICE_CREATED: 'Дата создания',
            DEVICE_UPDATE_SUCCESS: 'Информация об устройстве обновлена',
            DEVICE_DELETE_LINK: 'Удалить устройство',
            DEVICE_DELETE_TITLE: 'Удаление устройства',
            DEVICE_DELETE_DESCR: 'Это устройство будет удалено без возможности восстановления. Вы уверены, что хотите удалить его?',
            DEVICE_DELETE_SUCCESS: 'Устройство удалено',

            TRACK_TITLE: 'Запрос трека',
            TRACK_START: 'Начало',
            TRACK_FINISH: 'Конец',

            USERS_CREATE_TITLE: 'Новый пользователь',
            USERS_CREATE_LOGIN: 'Логин',
            USERS_CREATE_PASS: 'Пароль',
            USERS_CREATE_PASS_REPEAT: 'Повторите пароль',
            USERS_CREATE_NAME: 'Имя',
            USERS_CREATE_EMAIL: 'E-mail',
            USERS_CREATE_PHONE: 'Телефон',
            USERS_CREATE_SUCCESS: 'Новый пользователь добавлен',
            USERS_DELETE_TITLE: 'Удаление пользователя',
            USERS_DELETE_DESCR: 'Этот пользователь будет удалён без возможности восстановления',
            USERS_DELETE_SUCCESS: 'Пользователь удалён',

            USER_TITLE: 'Пользователь',
            USER_LOGIN: 'Логин',
            USER_NAME: 'Имя',
            USER_EMAIL: 'Эл.почта',
            USER_PHONE: 'Телефон',
            USER_CREATED: 'Создан',
            USER_UPDATE_SUCCESS: 'Информация о пользователе обновлена',

            FORM_ERROR_FIELDS: 'Ошибка заполнения формы',

            EConnection: 'При запросе к серверу произошла ошибка; возможно, потеряно соединение с Интернет',
            EUnknown: 'Произошла неизвестная ошибка; попробуйте обновить страницу и повторить запрос',
            EUserAuth: 'Неверный логин или пароль',
            EAuthUserNotFound: 'Пользователи не найдены',
            EAuthLoginEmpty: 'Введите логин и пароль',
            EAuthIncorrect: 'Неверный логин или пароль', 
            EDBRequest: 'Произошла ошибка при обращении к базе даных; попробуйте повторить позже',
            ETokenParseError: 'Ошибка при обработке токена',
            EUnauthRequest: 'Пожалуйста, авторизуйтесь для отправки этого запроса',
            ETokenInvalid: 'Недействительный токен; попробуйте авторизоваться заново',
            ETokenExpired: 'Срок действия токена истёк; авторизуйтесь заново',
            ETokenNotFound: 'Токен не найден',
            EUsersNotFound: 'Пользователи не найдены',
            EUserUpdateEmail: 'Поле e-mail не заполнено или заполнено некорректно', 
            EUserUpdateError: 'Ошибка при попытке обновления информации о пользователе',
            EUsersDeleteNotFound: 'Пользователь которого вы пытаетесь удалить не найден',
            ERequestInvalid: 'Произошла ошибка при обращении к базе данных',
            EDeviceNotFound: 'Запрошенное устройсто не найдено', 
            EDeviceCreateName: 'Укажите имя устройства',
            EDeviceCreateCode: 'Укажите код устройства', 
            EDeviceUpdateError: 'Ошибка при попытке обновления информации об устройстве',
            EDeviceDeleteNotFound: 'Удаляемое устройство не найдено',
            ETrackNotFound: 'За указанный промежуток времени треки не найдены'
        },

        EN: {

            APP_NAME: 'KAN Telematic Application',

            YES: 'Yes',
            NO: 'No',

            MENU_MAIN: 'Main',
            MENU_USERS: 'Users',
            MENU_DEVICES: 'Devices',
            MENU_LOGOUT: 'Logout',

            BTN_ADD: 'Add',
            BTN_CANCEL: 'Cancel',
            BTN_DELETE: 'Delete',
            BTN_EDIT: 'Edit',
            BTN_SAVE: 'Save',
            BTN_REQUEST: 'Request',

            PAGE_DEVICES: 'Devices',
            PAGE_USERS: 'Users',

            FORM_SHOW_PASSWORD: 'Show password',
            FORM_HIDE_PASSWORD: 'Hide password',

            STATUS_ONLINE: 'Online',
            STATUS_OFFLINE: 'Offline',

            DEVICES_CREATE_TITLE: 'New device',
            DEVICES_CREATE_NAME: 'Name',
            DEVICES_CREATE_CODE: 'Unique code',
            DEVICES_CREATE_DESCR: 'Description',
            DEVICES_CREATE_OWNER: 'Owner',
            DEVICES_CREATE_PRIVATE: 'Private',
            DEVICES_CREATE_SUCCESS: 'New device created', 
            DEVICES_UPDATE_SUCCESS: 'Device information updated',

            DEVICE_TITLE: 'Device',
            DEVICE_NAME: 'Name',
            DEVICE_CODE: 'Unique code',
            DEVICE_DESCR: 'Description',
            DEVICE_ISPRIVATE: 'Priate',
            DEVICE_CREATED: 'Created date',
            DEVICE_UPDATE_SUCCESS: 'Device information updated',
            DEVICE_DELETE_LINK: 'Delete device',
            DEVICE_DELETE_TITLE: 'Deleting device',
            DEVICE_DELETE_DESCR: 'This device will be permanently deleted. Are you sure you want to delete it?',
            DEVICE_DELETE_SUCCESS: 'Delete device',

            TRACK_TITLE: 'Request track',
            TRACK_START: 'Start',
            TRACK_FINISH: 'Finish',

            USERS_CREATE_TITLE: 'New user',
            USERS_CREATE_LOGIN: 'Login',
            USERS_CREATE_PASS: 'Password',
            USERS_CREATE_PASS_REPEAT: 'Repeat password',
            USERS_CREATE_NAME: 'Name',
            USERS_CREATE_EMAIL: 'E-mail',
            USERS_CREATE_PHONE: 'Phone',
            USERS_CREATE_SUCCESS: 'New user created',
            USERS_DELETE_TITLE: 'Delete user',
            USERS_DELETE_DESCR: 'This user will be permanently deleted. Are you sure you want to delete it?',
            USERS_DELETE_SUCCESS: 'User deleted',

            USER_TITLE: 'User',
            USER_LOGIN: 'Login',
            USER_NAME: 'Name',
            USER_EMAIL: 'E-mail',
            USER_PHONE: 'Phone',
            USER_CREATED: 'Created',
            USER_UPDATE_SUCCESS: 'User information updated',

            FORM_ERROR_FIELDS: 'Form filling error',

            EConnection: 'An error occurred while requesting the server; Internet connection may have been lost',
            EUnknown: 'An unknown error has occurred; try refreshing the page and retrying the request',
            EUserAuth: 'Incorrect login or password',
            EAuthUserNotFound: 'Users not found',
            EAuthLoginEmpty: 'Enter login & password',
            EAuthIncorrect: 'Wrong login or password', 
            EDBRequest: 'An error occurred while accessing the database; try again later',
            ETokenParseError: 'Error processing token',
            EUnauthRequest: 'Please login to submit this request',
            ETokenInvalid: 'Invalid token; try logging in again',
            ETokenExpired: 'The token has expired; please log in again',
            ETokenNotFound: 'Token not found',
            EUsersNotFound: 'Users not found',
            EUserUpdateEmail: 'The e-mail field is not filled or filled incorrectly', 
            EUserUpdateError: 'User information update error',
            EUsersDeleteNotFound: 'The user you are trying to delete was not found',
            ERequestInvalid: 'An error occurred while accessing the database',
            EDeviceNotFound: 'Device not found', 
            EDeviceCreateName: 'Enter device name',
            EDeviceCreateCode: 'Enter device code', 
            EDeviceUpdateError: 'Device information update error',
            EDeviceDeleteNotFound: 'Device for delete not found',
            ETrackNotFound: 'No tracks found for the specified time period'
        }
    },

    getters: {

        getTranslate: (state, getters, rootState) => state[rootState.language.lang]
    }
}