/**
 * Адреса серверов устанавливаются при запуске приложения в created() компонента App.js
 */
export default {

    state: {

        serverHttp:     '',
        serverWs:       '',
        serverMapTiles: ''
    },

    mutations: {

        setServerHttp:      (state, data) => state.serverHttp       = data,
        setServerWs:        (state, data) => state.serverWs         = data,
        setServerMapTiles:  (state, data) => state.serverMapTiles   = data
    }
}