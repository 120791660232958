<template>
    <div class="page page--auth">
        <KTAAuth />
    </div>
</template>

<script>
import KTAAuth from '@/components/KTAAuth'

export default {

    name: 'ViewAuth',

    components: {

        KTAAuth
    }
}
</script>