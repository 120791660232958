export default {

    state: {

        authProcess:    false,
        authMsg:        {}
    },

    actions: {

        async sendAuth({rootState, state, dispatch}, payload) {

            state.authProcess = true

            const request = await dispatch('fetchRequest', {

                address: `${rootState.config.serverHttp}/auth.php`, 
                method: 'POST',
                data: payload
            })

            if(!request.error && request.token) {

                dispatch('addToken', request.token)
                state.authMsg = { success: true, text: '' }
                

            } else {

                state.authMsg = { success: false, text: request.error }
            }

            state.authProcess = false
        }
    }
}