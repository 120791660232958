export default {

    state: {

        device:               {},
        deviceProcess:        false,
        deviceMsg:            {},

        deviceUpdateProcess:  false,
        deviceUpdateMsg:      {},

        deviceDeleteProcess: false,
        deviceDeleteMsg: {}
    },

    actions: {

        /**
         * readDevice
         * 
         * Получает информацию об устройстве по ID
         * 
         * @param {Object} payload - параметры для выборки и фильтрации результата
         */
        async readDevice({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.deviceProcess = true

                let params = ''

                if(payload) {

                    for( let param in payload) {

                        params.length ?
                            params += '&' + param + '=' + payload[param] :
                            params = '?' + param + '=' + payload[param]
                    }
                }

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/devices.php${params}`, 
                    method: 'GET',
                    token
                })

                if(!request.error) {

                    state.device = request
                    state.deviceMsg = { success: true, text: '' }

                } else {

                    state.deviceMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.deviceProcess = false

            } else {

                state.deviceMsg = { success: false, text: 'ETokenNotFound' }
            }

            return state.device
        },

        /**
         * updateDevice
         * 
         * Обновляет информацию о пользователе
         * 
         * @param {Object} payload - поля формы редактирования устройства
         */
        async updateDevice({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.deviceUpdateProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/devices.php`, 
                    method: 'PUT',
                    data: payload,
                    token
                })

                if(!request.error) {

                    state.deviceUpdateMsg = { success: true, text: getters.getTranslate.DEVICE_UPDATE_SUCCESS }

                } else {

                    state.deviceUpdateMsg = { success: false, text: getters.getTranslate[request.error] || request.error }
                }

                state.deviceUpdateProcess = false

            } else {

                state.deviceUpdateMsg = { success: false, text: 'ETokenNotFound' }
            }
        },

        /**
         * 
         * @param {String} payload - ID удаляемого устройства 
         */
        async deleteDevice({rootState, state, getters, dispatch}, payload) {

            const token = rootState.token.token

            if(token) {

                state.deviceDeleteProcess = true

                const request = await dispatch('fetchRequest', {

                    address: `${rootState.config.serverHttp}/devices.php?id=${payload}`, 
                    method: 'DELETE',
                    token
                })

                if(!request.error) {

                    state.deviceDeleteMsg = { success: true, text: getters.getTranslate.DEVICE_DELETE_SUCCESS }

                } else {

                    state.deviceDeleteMsg = { success: false, text: request.error }
                }

                state.deviceDeleteProcess = false

            } else {

                state.deviceDeleteMsg = { success: false, text: 'ETokenNotFound' }
            }
        }
    },

    mutations: {

        setDeviceMsg:       (state, data) => state.deviceMsg = data,
        setDeviceUpdateMsg: (state, data) => state.deviceUpdateMsg = data,
        setDeviceDeleteMsg: (state, data) => state.deviceDeleteMsg = data
    }
}