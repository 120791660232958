<template>
    <div class="page page--users">
        <h1 class="page__header">{{ getTranslate.PAGE_USERS }}</h1>
        <KTAUsers />
    </div>
</template>

<script>
import KTAUsers from '@/components/KTAUsers.vue'

import { mapGetters } from 'vuex'

export default {

    name: 'ViewUsers',

    components: {

        KTAUsers
    },

    computed: {

        ...mapGetters([

            'getTranslate'
        ])
    }
}
</script>
