export default {

    state: {

        token: null
    },

    actions: {

        /**
         * Добавляет токен, полученный в случае успешной авторизации или проверяет его наличие в локальном хранищище
         * 
         * @param {String} payload - токен для запросов
         */
        addToken ({state}, payload) {

            if(payload) {

                state.token = payload
                localStorage.setItem('KTA_TOKEN', payload)

            } else if(localStorage.getItem('KTA_TOKEN')) {

                state.token = localStorage.getItem('KTA_TOKEN')

            } else {

                state.token = null
            }
        }
    }
}