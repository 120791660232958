<template>
    <div class="map" id="KTAMap"></div>
</template>

<script>
import L from 'leaflet'
import { mapState, mapActions } from 'vuex'

export default {

    name: 'KTAMap',

    data() {

        return {

            mapCenter: [0, 0]
        }
    },

    computed: {

        ...mapState({

            serverMapTiles: state => state.config.serverMapTiles,
            points: state => state.points.points
        })
    },

    methods: {

        ...mapActions([

            'readPoints'
        ])
    },

    mounted() {

        const self = this

        if(localStorage.getItem('KTA_MAP_CENTER')) {

            const coords = JSON.parse(localStorage.getItem('KTA_MAP_CENTER'))

            this.mapCenter = [ coords.lat, coords.lng ]

        } else {

            this.mapCenter = [ 0, 0 ]
        }

        self.map = L.map('KTAMap', {
            center: this.mapCenter,
            zoomControl: false,
            zoom: localStorage.getItem('KTA_MAP_ZOOM_LVL'),
            maxZoom: 19,
            attributionControl: false
        })

        // Сохраняем значение в памяти браузера при изменении масштаба карты
        self.map.on('zoomend', function() {

            localStorage.setItem('KTA_MAP_ZOOM_LVL', self.map._zoom)
        })

        // Сохраняем участок с видимой областью карты
        self.map.on('moveend', () => {

            localStorage.setItem('KTA_MAP_CENTER', JSON.stringify(self.map.getCenter()))
        })

        // Добавляем управление зумом
        L.control.zoom({ position: 'bottomright' }).addTo(self.map)

        // Удаляем копирайт Leaflet
        L.control.attribution().remove()

        // Основная карта
        L.tileLayer(this.serverMapTiles, {
            minZoom: 5,
            maxZoom: 19,
            noWrap: true
        }).addTo(self.map)

        self.readPoints().then(

            () => {

                if(self.points.length) {

                    let latlngs = []

                    self.points.map( point => {

                        if(
                            Number(point.lat) !== 0 && 
                            Number(point.lng) !== 0 &&
                            Number(point.lat) !== -1 && 
                            Number(point.lat) !== -1
                        ) {

                            latlngs.push([ point.lat, point.lng ]) 
                        }
                    })

                    L.polyline(latlngs, {color: 'red'}).addTo(self.map)

                    // self.map.fitBounds(polyline.getBounds())
                }
            }
        )
    }
}
</script>

<style lang="scss">
@import '~@/../node_modules/leaflet/dist/leaflet.css';
@import '~@/../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
@import '@/assets/styles/scss/components/map.scss';
</style>